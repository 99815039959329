/* eslint-disable no-param-reassign */
const { createSlice } = require('@reduxjs/toolkit');

// Initial State
const initialState = {
  menuTab: null,
  openKeys: [],
  htmlPreviewContent: null,
};

const AdminStateSlice = createSlice({
  name: 'adminState',
  initialState,
  reducers: {
    updateMenuTab(state, action) {
      state.menuTab = action.payload;
    },
    updateOpenKeys(state, action) {
      state.openKeys = action.payload;
    },
    updateHtmlContent(state, action) {
      state.htmlPreviewContent = action.payload;
    },
  },
});

export const { updateMenuTab, updateOpenKeys, updateHtmlContent } =
  AdminStateSlice.actions;

export default AdminStateSlice.reducer;
