import './spinner.scss';

function Spinner() {
  return (
    <div className="vps-app-spinner">
      <div id="wrapper">
        <div id="corpus" />
        <div id="spinner" />
      </div>
      <div id="text">&nbsp;Loading ...</div>
    </div>
  );
}

export default Spinner;
