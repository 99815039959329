// ** Redux Imports
import { combineReducers } from 'redux';

import adminStateReducer from './AdminState';
import paginationReducer from './Pagination';
import userStateReducer from './UserState';

// ** Reducers Imports

const rootReducerT = combineReducers({
  appUserState: userStateReducer,
  paginationData: paginationReducer,
  adminState: adminStateReducer,
});

const rootReducer = (state, action) => {
  // Clear all state on logout
  if (action.type === 'auth/logout') {
    return rootReducerT(undefined, action);
  }
  return rootReducerT(state, action);
};

export default rootReducer;
