/* eslint-disable no-param-reassign */
const { createSlice } = require('@reduxjs/toolkit');

// Initial State
const initialState = {
  userData: null,
  role: null,
  token: null,
};

const UserStateSlice = createSlice({
  name: 'appUserState',
  initialState,
  reducers: {
    updateUserStateValues(state, action) {
      const { key, value } = action.payload;
      state[key] = { ...state[key], ...value };
    },
    updateUserState(state, action) {
      state.userData = action.payload;
      state.role = action.payload?.role || 'user';
    },
    updateToken(state, action) {
      state.token = action.payload;
    },
    handleLogout(state) {
      state.token = null;
      state.userData = null;
      state.role = null;
    },
  },
});

export const {
  updateUserState,
  updateToken,
  handleLogout,
  updateUserStateValues,
} = UserStateSlice.actions;

export default UserStateSlice.reducer;
