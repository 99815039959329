import { App } from 'antd';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeFormat from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import Spinner from '@core/components/Spinner/Spinner';
import { persistor, store } from '@redux/store';

import reportWebVitals from './reportWebVitals';

// style css
import './App.scss';
import './global-styles.scss';

dayjs.extend(localizedFormat);
dayjs.extend(relativeFormat);
dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

/* The code `const LazyApp = React.lazy(() => import('./App'));` is using the `React.lazy()` function
to lazily load the `App` component. */
const LazyApp = React.lazy(() => import('./App'));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <App
        message={{
          maxCount: 2,
          duration: 2,
        }}
        notification={{
          placement: 'topRight',
          maxCount: 2,
        }}
      >
        <React.Suspense fallback={<Spinner />}>
          <Router>
            <LazyApp />
          </Router>
        </React.Suspense>
      </App>
    </PersistGate>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
